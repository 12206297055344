import { Component, OnInit, Input } from '@angular/core';
import { Breadcrumbs } from '../breadcrumbs';


@Component({
	selector: 'app-sub-header',
	templateUrl: './sub-header.component.html',
	styleUrls: ['./sub-header.component.css']
})
export class SubHeaderComponent implements OnInit {
	@Input('bread') bread: Breadcrumbs;

	access_client = +localStorage.getItem('access_client');
	access_sales = +localStorage.getItem('access_sales');
	access_acc = +localStorage.getItem('access_acc');
	access_admin1 = +localStorage.getItem('access_admin1');
	access_admin2 = +localStorage.getItem('access_admin2');
	home = '/social';
	status: boolean = false;
	constructor() { }

	ngOnInit() {
		localStorage.setItem('hint', '0');
	}

	clickEvent() {
		this.status = !this.status;
	}

}