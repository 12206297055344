import { Component, OnInit } from '@angular/core';
import { Breadcrumbs } from 'src/app/shared/breadcrumbs';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/User';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { DomSanitizer } from '@angular/platform-browser';
import { GeneralService } from '../services/general.service';
import { Router } from '@angular/router';

declare var $: any;
@Component({
	selector: 'app-user-profile',
	templateUrl: './user-profile.component.html',
	styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
	breadcrumb: Breadcrumbs[] = [];
	registerForm: FormGroup;
	email = '';
	role = '';
	branch = '';
	loading = false;
	imgtypeerr: boolean = false;
	imgForm: FormGroup;
	profimg = '';
	passwordForm: FormGroup;
	consultant = '';
	mismatch: boolean = false;
	loginrole = +localStorage.getItem('role');
	constructor(
		private router: Router,
		private _http: HttpClient,
		private toastr: ToastrService,
		public sanitizer: DomSanitizer,
		private formBuilder: FormBuilder,
		public generalService: GeneralService,
	) { }

	ngOnInit() {
		this.breadcrumb.push(new Breadcrumbs('', '', 'User Profile', '', ''));
		this.registerForm = this.formBuilder.group({
			fname: ['', [Validators.required]],
			lname: ['', [Validators.required]],
			contact: [''],
			userid: ['', [Validators.required]]
		});

		this.getProfile();

		document.getElementById("imgFileUpload").addEventListener("click", function () {
			document.getElementById("file").click();
		});

		this.imgForm = this.formBuilder.group({
			fileimg: [''],
			uploadfile: []
		});
		this.passwordForm = this.formBuilder.group({
			currentpass: ['', [Validators.required]],
			newpass: ['', [Validators.required]],
			confirmpass: ['', [Validators.required],
			],
		});
	}

	passwordConfirming() {
		let newpass = this.passwordForm.get('newpass').value;
		let confpass = this.passwordForm.get('confirmpass').value;
		if (newpass !== confpass) {
			this.mismatch = true;
			//return {invalid: true};
		} else {
			this.mismatch = false;;
		}
	}

	getProfile() {
		this._http.post(User.API_ENDPOINT + 'get-user-profile', '')
			.subscribe((response: Response) => {
				//console.log(response);
				let res = { 'results': JSON.stringify(response) };
				let myjson = JSON.parse(res.results);
				const status = myjson.status;
				if (status === 200) {
					this.email = myjson.data['profile'][0].username;
					this.role = myjson.data['profile'][0].role;
					if (this.role != '6') {
						this.branch = myjson.data['profile'][0].br_name;
					}

					this.profimg = myjson.data['profile'][0].profile_img;
					if (this.role == '3') {
						this.consultant = myjson.data['consultant'][0].fname + " " + myjson.data['consultant'][0].lname;
					}
					this.registerForm.controls['userid'].setValue(myjson.data['profile'][0].user_id);
					this.registerForm.controls['fname'].setValue(myjson.data['profile'][0].fname);
					this.registerForm.controls['lname'].setValue(myjson.data['profile'][0].lname);
					this.registerForm.controls['contact'].setValue(myjson.data['profile'][0].contactno);
				} else {
					this.generalService.logout();
				}
			});
	}

	updateProfile() {
		this._http
			.post(User.API_ENDPOINT + 'update-user-profile', JSON.stringify(this.registerForm.value))
			.subscribe((response: Response) => {
				let res = { 'results': JSON.stringify(response) };
				let myjson = JSON.parse(res.results);
				const status = myjson.status;
				this.loading = false;
				if (status === 200) {
					this.toastr.success('User profile updated successfully,', 'Profile Updated');
				} else if (status === 400) {
					this.toastr.error('Oh snap! try submitting again', 'Failed to Update');
				} else {
					this.generalService.logout();
				}
			});
	}

	fileChange(event) {
		let file = event.target.files[0];
		let filename = event.target.files[0].name;
		let filesize = event.target.files[0].size;
		let ext = filename.substring(filename.lastIndexOf('.') + 1).toLowerCase();
		this.imgForm.get('fileimg').setValue(file);
		let validFormats = ['jpg', 'jpeg', 'png'];
		const formData = new FormData();
		formData.append('uploadfile', this.imgForm.get('fileimg').value);
		formData.append('flag', '1');
		formData.append('localimg', localStorage.getItem('img'));
		if (validFormats.indexOf(ext) !== -1) {
			this.imgtypeerr = false;
			this._http
				.post(User.API_ENDPOINT + 'update-profile-image', formData)
				.subscribe((response: Response) => {
					//console.log(response);
					let res = { 'results': JSON.stringify(response) };
					let myjson = JSON.parse(res.results);
					const status = myjson.status;
					this.loading = false;
					if (status === 200) {
						this.profimg = myjson.data['profileimg'];
						localStorage.setItem('img', this.profimg);
						this.toastr.success('User profile image updated successfully. Please refresh to view change', 'Profile Image Updated');
					} else if (status === 400) {
						this.toastr.error('Oh snap!try submitting again', 'Failed to Update Profile Image');
					} else {
						this.generalService.logout();
					}
				});
		} else {
			this.imgtypeerr = true;
		}
	}

	markFormTouched(forms) {
		for (let i in forms.controls)
			forms.controls[i].markAsTouched();
	}

	updatePassword() {
		this.markFormTouched(this.passwordForm);
		if (this.passwordForm.valid) {
			this._http
				.post(User.API_ENDPOINT + 'update-user-password', JSON.stringify(this.passwordForm.value))
				.subscribe((response: Response) => {
					const res = { 'results': JSON.stringify(response) };
					const json = JSON.parse(res.results);
					const status = json.status;
					if (status === 200) {
						this.passwordForm.reset();
						this.toastr.success('User password updated successfully', 'Password Updated');
					} else if (status === 400) {
						this.toastr.error('Oh snap! try submitting again', 'Failed to Update Password');
					} else {
						this.generalService.logout();
					}
				});
		} else {
			this.toastr.warning('Please check if the password provided is correct', 'Incomplete Form');
		}
	}

	viewLog() {
		let userid = localStorage.getItem('login_user');
		this.router.navigate(['/administration/view-log'], { queryParams: { value: btoa(userid) } });
	}

}