import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpResponse,
	HttpErrorResponse
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from '../services/auth.service';
import { RequestOptions, Headers } from '@angular/http';
@Injectable()
export class MyInterceptor implements HttpInterceptor {
	public token: string;
	constructor(private authService: AuthService, private options: RequestOptions) { }

	//function which will be called for all http calls
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {

		if (this.authService.token) {
			this.token = this.authService.token;
		} else {
			this.token = '';
		}
		const updatedRequest = request.clone({
			headers: request.headers.set("Token", this.token)
		});

		//logging the updated Parameters to browser's console
		return next.handle(updatedRequest).pipe(
			tap(
				event => {
					//logging the http response to browser's console in case of a success
					if (event instanceof HttpResponse) {
					}
				},
				error => {
					//logging the http response to browser's console in case of a failuer
					if (event instanceof HttpResponse) {
					}
				}
			)
		);
	}
}