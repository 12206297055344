import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { GeneralService } from '../services/general.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ViewChild, ElementRef } from '@angular/core';
import { UserIdleService } from 'angular-user-idle';
import { User } from '../models/User';
import { DomSanitizer } from '@angular/platform-browser';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
declare var jQuery: any;

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
	@ViewChild('idleModal') idleModal: ElementRef;
	userarr = [];
	newarr = [];
	userid = +localStorage.getItem('login_user');
	remaining = 60;
	notif: any;
	searchVal: boolean = false;
	timesheetForm: FormGroup;
	now: any;
	enow: any;
	today = new Date();
	jstoday = '';
	loading: boolean = false;
	btnText = 'Start Time';
	submitted: boolean = false;
	timesheet = [];
	time_id = 0;
	atime: any;
	btime: any;

	constructor(
		private router: Router,
		private _http: HttpClient,
		private toastr: ToastrService,
		public sanitizer: DomSanitizer,
		private userIdle: UserIdleService,
		public generalService: GeneralService,
	) { }

	ngOnInit() {
		this.jstoday = formatDate(this.today, 'dd-MM-yyyy', 'en-US', '+0530');
		this.userIdle.startWatching();
		this.userIdle.onTimerStart().subscribe(count => {
			//console.log(count);
			this.remaining -= 1;
			if (count == 1)
				jQuery(this.idleModal.nativeElement).modal('show');
		});
		this.userIdle.onTimeout().subscribe(() => {
			console.log('Time is up!');
			jQuery(this.idleModal.nativeElement).modal('hide');
			this.userIdle.stopWatching();
			this.generalService.screenLock();
		});
		this.userIdle.ping$.subscribe(() => console.log("PING"));
		this.userarr = JSON.parse(localStorage.getItem("srchArr"));
		this.timesheetForm = new FormGroup({
			'timeid': new FormControl(0),
			'type': new FormControl(0, Validators.required),
			'description': new FormControl(null),
			'sdate': new FormControl(this.jstoday, Validators.required),
			'stime': new FormControl('', Validators.required),
			'edate': new FormControl(this.jstoday),
			'etime': new FormControl('00:00'),
			'userid': new FormControl(this.userid, Validators.required)
		})
		this.getNotification();
	}

	getNotification() {
		this._http.post(User.API_ENDPOINT + 'get-notification', JSON.stringify({ flag: 1 }))
			.subscribe((response: Response) => {
				let res = { 'results': JSON.stringify(response) };
				let myjson = JSON.parse(res.results);
				const status = myjson.status;
				if (status === 200) {
					this.notif = myjson.data.row;
				}
			});
	}

	search(x) {
		if (x != '') {
			this.searchVal = true;
			this.newarr = [];
			this.newarr = this.userarr.filter(function (hero) {
				return (hero.name.toLowerCase().includes(x.toLowerCase())) || (hero.email.toLowerCase().includes(x.toLowerCase())) || (hero.mobile.toLowerCase().includes(x.toLowerCase()));
			});
		} else {
			this.newarr = [];
			this.searchVal = false;
		}
	}

	searchPage(type, id) {
		if (type == 1) {
			this.router.navigate(['/clients/client'], { queryParams: { value: btoa(id) } });
		} else {
			this.router.navigate(['/sales/pipeline-clients-update'], { queryParams: { value: btoa(id) } });
		}
	}

	templogout() {
		this.userIdle.stopWatching();
		this.generalService.screenLock();
	}

	waitmore() {
		this.remaining = 60;
		this.userIdle.resetTimer();
	}

	get f() {
		return this.timesheetForm.controls;
	}

	closeModal() {

	}

	openTime() {
		//console.log(localStorage.getItem('timeArr'));
		let timeArr = JSON.parse(localStorage.getItem('timeArr'));

		if (timeArr != '') {
			this.btnText = 'Close Time';
			this.time_id = timeArr.map(function (item) { return item['time_id']; });
			var stime = timeArr.map(function (item) { return item['stime']; });
			var ttype = timeArr.map(function (item) { return item['timetype']; });
			var descr = timeArr.map(function (item) { return item['description']; });
			var sdate = timeArr.map(function (item) { return item['sdate']; });

			this.timesheetForm.controls['timeid'].setValue(this.time_id.toString());
			this.timesheetForm.controls['type'].setValue(ttype.toString());
			this.timesheetForm.controls['description'].setValue(descr.toString());
			this.timesheetForm.controls['sdate'].setValue(sdate.toString());
			this.timesheetForm.controls['stime'].setValue(stime.toString().substring(0, 5));
			this.timesheetForm.controls['userid'].setValue(this.userid);

			this.atime = setInterval(() => {
				this.enow = Date.now();
			}, 1);

		} else {
			this.btnText = 'Start Time';
			this.atime = setInterval(() => {
				this.now = Date.now();
				this.enow = Date.now();
			}, 1);
		}

	}

	onSubmit() {
		this.submitted = true;
		if (this.timesheetForm.valid && this.timesheetForm.controls['type'].value != 0) {
			this.btnText = 'Please wait...';
			this.loading = true;
			//console.log(this.timesheetForm.controls['timeid'].value)
			if (this.timesheetForm.controls['timeid'].value == 0) {
				this._http
					.post(User.API_ENDPOINT + 'save-user-timesheet', JSON.stringify(this.timesheetForm.value))
					.subscribe((response: Response) => {
						//console.log(response);
						const res = { 'results': JSON.stringify(response) };
						const json = JSON.parse(res.results);
						const status = json.status;
						if (status === 200) {
							this.submitted = false;
							this.toastr.success('New activity in timesheet added successfully', 'New Activity Added');
							this.timesheet.push({
								'time_id': json.data.timeid,
								'timetype': this.timesheetForm.controls['type'].value,
								'description': this.timesheetForm.controls['description'].value,
								'sdate': this.timesheetForm.controls['sdate'].value,
								'stime': this.timesheetForm.controls['stime'].value
							})
							localStorage.setItem('timeArr', JSON.stringify(this.timesheet));
							this.loading = false;
							clearInterval(this.atime);
							this.openTime();
						} else if (status === 400) {
							this.toastr.error('Oh snap! try submitting again', 'Failed to Add');
						} else {
							this.generalService.logout();
						}
					});
			} else {
				this._http
					.post(User.API_ENDPOINT + 'update-user-timesheet', JSON.stringify(this.timesheetForm.value))
					.subscribe((response: Response) => {
						//console.log(response);
						const res = { 'results': JSON.stringify(response) };
						const json = JSON.parse(res.results);
						const status = json.status;
						if (status === 200) {
							this.submitted = false;
							this.loading = false;
							if (json.data.status == true) {
								localStorage.setItem('timeArr', JSON.stringify([]));
								this.time_id = 0;
								this.timesheetForm.controls['timeid'].setValue(this.time_id.toString());
								clearInterval(this.atime);
								this.openTime();
								this.toastr.success('Close activity in timesheet successfully', 'Activity Closed');
							} else {
								this.toastr.warning('Please refresh the page and try again', 'Failed to Update');
							}
						} else if (status === 400) {
							this.toastr.error('Oh snap! try submitting again', 'Failed to Close');
						} else {
							this.generalService.logout();
						}
					});
			}
		} else {
			this.toastr.warning('Oh snap! please check if all mandatory fields are provided', 'Incomplete Form');
		}
	}
}
