import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { UserIdleModule } from 'angular-user-idle';

import { AuthService } from './services/auth.service';
import { MyInterceptor } from './services/myinterceptor';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { TermsAndConditionsComponent } from './shared/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './shared/privacy-policy/privacy-policy.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { CustomPreloadingService } from './services/custom-preloading.service';
import { UserProfileComponent } from './shared/user-profile/user-profile.component';
import { RoleGuard } from './role.guard';
import { CreatePasswordComponent } from './create-password/create-password.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { UnlockComponent } from './unlock/unlock.component';

//configure custom preloader based on role
let role = localStorage.getItem('role');
const appRoutes: Routes = [
	{ path: 'sadmin', data: { preload: true, loadAfterSeconds: 5, role: 0 }, loadChildren: './sadmin/sadmin.module#SadminModule', canActivate: [RoleGuard] },
	{ path: 'dashboard', data: { preload: true, loadAfterSeconds: 2, role: 1 }, loadChildren: './admin/admin.module#AdminModule', canActivate: [RoleGuard] },
	{ path: 'mail', data: { preload: true, loadAfterSeconds: 5, role: 1 }, loadChildren: './mail/mail.module#MailModule', canActivate: [RoleGuard] },
	{ path: 'clients', data: { preload: true, loadAfterSeconds: 3, role: 1 }, loadChildren: './clients/clients.module#ClientsModule', canActivate: [RoleGuard] },
	{ path: 'sales', data: { preload: true, loadAfterSeconds: 5, role: 1 }, loadChildren: './sales/sales.module#SalesModule', canActivate: [RoleGuard] },
	{ path: 'accounts', data: { preload: true, loadAfterSeconds: 5, role: 1 }, loadChildren: './accounts/accounts.module#AccountsModule', canActivate: [RoleGuard] },
	{ path: 'social', data: { preload: true, loadAfterSeconds: 5, role: 1 }, loadChildren: './social/social.module#SocialModule', canActivate: [RoleGuard] },
	{ path: 'administration', data: { preload: true, loadAfterSeconds: 3, role: 1 }, loadChildren: './administration/administration.module#AdministrationModule', canActivate: [RoleGuard] },
	{ path: 'applicant', data: { preload: true, loadAfterSeconds: 2, role: 3 }, loadChildren: './applicant/applicant.module#ApplicantModule', canActivate: [RoleGuard] },
	{ path: 'coaching', data: { preload: true, loadAfterSeconds: 5, role: 4 }, loadChildren: './coaching/coaching.module#CoachingModule', canActivate: [RoleGuard] },
	{ path: 'salesperson', data: { preload: true, loadAfterSeconds: 5, role: 6 }, loadChildren: './sales-person/sales-person.module#SalesPersonModule', canActivate: [RoleGuard] },
	{ path: 'support', data: { preload: true, loadAfterSeconds: 5, role: 5 }, loadChildren: './support-person/support-person.module#SupportPersonModule', canActivate: [RoleGuard] },

	{ path: '', component: LoginComponent },
	//{ path: 'reset-password', component: PasswordRecoveryComponent},
	{ path: 'user-lock', component: UnlockComponent },
	{ path: 'terms-and-conditions', component: TermsAndConditionsComponent },
	{ path: 'privacy-policy', component: PrivacyPolicyComponent },
	{ path: 'reset-password', component: ResetPasswordComponent },
	{ path: 'user-profile', component: UserProfileComponent },
	{ path: 'create-password', component: CreatePasswordComponent },
	{ path: '**', component: NotFoundComponent },
];

@NgModule({
	declarations: [
		AppComponent,
		LoginComponent,
		PasswordRecoveryComponent,
		ResetPasswordComponent,
		CreatePasswordComponent,
		NotFoundComponent,
		UnlockComponent,
	],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		SharedModule,
		HttpClientModule,
		FormsModule,
		ReactiveFormsModule,
		SelectDropDownModule,
		SlimLoadingBarModule.forRoot(),
		ToastrModule.forRoot({
			timeOut: 5000,
			newestOnTop: true,
			positionClass: 'toast-bottom-left',
			preventDuplicates: true,
		}),
		UserIdleModule.forRoot({ idle: 6000, timeout: 60, ping: 300 }),
		RouterModule.forRoot(appRoutes, { preloadingStrategy: CustomPreloadingService })
	],
	providers: [AuthService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: MyInterceptor,
			multi: true
		}
	],
	exports: [RouterModule],
	bootstrap: [AppComponent]
})
export class AppModule { }
