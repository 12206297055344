import { Component, OnInit, Input } from '@angular/core';
import { Breadcrumbs } from 'src/app/shared/breadcrumbs';

@Component({
	selector: 'app-appl-sub-header',
	templateUrl: './appl-sub-header.component.html',
	styleUrls: ['./appl-sub-header.component.css']
})
export class ApplSubHeaderComponent implements OnInit {

	@Input('bread') bread: Breadcrumbs;

	constructor() { }

	ngOnInit() {
	}

}
