import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { GeneralService } from '../shared/services/general.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'app-unlock',
	templateUrl: './unlock.component.html',
	styleUrls: ['./unlock.component.css']
})
export class UnlockComponent implements OnInit {
	loginForm: FormGroup;
	submitted = false;
	error = '';
	loading = false;
	btnText = 'UNLOCK';
	dpimg = localStorage.getItem('img');
	name = localStorage.getItem('userName');
	constructor(
		private formBuilder: FormBuilder,
		public generalService: GeneralService,
		private router: Router,
		private _http: HttpClient,
		public sanitizer: DomSanitizer) { }

	ngOnInit() {
		if (localStorage.getItem('userName') == null) {
			this.router.navigate(['/']);
		}
		this.loginForm = this.formBuilder.group({
			uname: ['', [Validators.required, Validators.pattern(/[aA-zZ0-9'-]$/)]],
			password: ['', [Validators.required, Validators.pattern(/[aA-zZ0-9'-]$/)]],
		});
	}

	get f() { return this.loginForm.controls; }

	onSubmit() {
		this.submitted = true;
		this.loginForm.controls['uname'].setValue(localStorage.getItem('login_username'));
		if (this.loginForm.invalid) {
			return;
		} else {
			this.btnText = 'PLEASE WAIT...';
			this.loading = true;
			this.generalService.login(JSON.stringify(this.loginForm.value))
				.subscribe(result => {
					//console.log(result);
					if (result[0] === true) {
						this.error = '';
						if (result[1] === 0) {
							this.router.navigate(['sadmin']);
						} else if (result[1] === 1 || result[1] === 2) {
							this.router.navigate(['dashboard']);
						} else if (result[1] === 3) {
							this.router.navigate(['applicant']);
						} else {
							this.loading = false;
							this.btnText = 'SIGN IN';
							this.error = '* Something Went Wrong, Invalid Role!';
						}
					} else {
						this.btnText = 'SIGN IN';
						this.loading = false;
						if (result[1] === 400) {
							this.error = '* The Password Provided is Invalid';
						} else if (result[1] === 401) {
							this.error = '* Your Account is Pending Activation';
						} else {
							this.error = '* Please Provide a Valid Username';
						}
					}
				});
		}
	}

}
