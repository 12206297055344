import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Response } from '@angular/http';
import { GeneralService } from '../shared/services/general.service';
import { HttpClient } from '@angular/common/http';
import { User } from '../shared/models/User';

@Component({
	selector: 'app-reset-password',
	templateUrl: './reset-password.component.html',
	styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
	submitted = false;
	error = '';
	loading = false;
	btnText = 'UPDATE';
	model: any = {};
	errorconfpass = false;
	errornewpass = false;
	mismatcherr = false;
	load = false;
	constructor(
		public generalService: GeneralService,
		private router: Router,
		private _http: HttpClient,
	) { }

	ngOnInit() {

	}

	updatepsd() {
		let token = window.location.search.substring(7);
		let password = this.model.newpass;
		let cpassword = this.model.confpass;
		if (password == null) {
			this.errornewpass = true;
		} else if (cpassword == null) {
			this.errornewpass = false;
			this.errorconfpass = true;
		} else if (password != cpassword) {
			this.errorconfpass = false;
			this.mismatcherr = true;
		} else {
			this.mismatcherr = false;
			this.loading = true;
			this._http.post(User.API_ENDPOINT + 'modify-password', JSON.stringify({ newpassword: this.model.newpass, token: token }))
				.subscribe((response: Response) => {
					this.loading = false;
					let res = { 'results': JSON.stringify(response) };
					let myjson = JSON.parse(res.results);
					let status = myjson.status;
					if (status == 200) {
						this.error = 'Password Updated Successfully. Please login to access your account';
						this.stateChange();
					} else if (status == 400) {
						this.error = '* Error in updating Password';
					}
				});
		}
	}

	stateChange() {
		setTimeout(function () {
			this.generalService.logout();
		}, 1000);
	}

}