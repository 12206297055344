import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';
@Injectable({
	providedIn: 'root'
})
export class AuthService {
	public token: string;
	constructor(private http: Http) {
		// set token if saved in local storage
		var currentUser = JSON.parse(localStorage.getItem('currentUser'));
		this.token = currentUser && currentUser.token;

	}
}
