import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RoleGuard implements CanActivate {
	constructor(private router: Router) { }
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		if (localStorage.getItem('currentUser')) {
			const r1 = next.data['role'];
			//var currentUser = JSON.parse(localStorage.getItem('currentUser'));
			var r2 = localStorage.getItem('role');
			if (r2 == '2') {
				r2 = '1';
			}
			if (r1 == r2) {
				return true;
			} else {
				this.router.navigate(['']);
				return false;
			}
		} else {
			this.router.navigate(['']);
			return false;
		}
	}



}
