import { Injectable } from '@angular/core';
import { Http, Headers, Response } from '@angular/http';

import { User } from '../models/User';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})

export class GeneralService {
	token: any;
	r = [];
	name: any;
	fileCode: any;
	img: any;
	login_user: any;
	userarr = [];
	srchArr = [];
	rowcount = '10';
	timesheet = [];

	constructor(private http: Http, private router: Router, private _http: HttpClient,) { }

	login(d) {
		return this.http.post(User.API_ENDPOINT + 'authentication', d, { headers: new Headers({ 'Content-Type': 'application/json' }) })
			.pipe(map((response: Response) => {
				//console.log(response);

				const token = response.json() && response.json().token;
				this.r[0] = false;
				if (token) {
					this.token = token;
					this.name = response.json() && response.json().name;
					this.fileCode = response.json() && response.json().file_code;
					this.img = response.json() && response.json().img;
					this.login_user = response.json() && response.json().login_user;
					this.rowcount = response.json() && response.json().rowCount;
					this.rowcount = (this.rowcount != '') ? this.rowcount : '10';

					localStorage.setItem('currentUser', JSON.stringify({ token: token }));
					localStorage.setItem('userName', this.name);
					localStorage.setItem('login_username', response.json() && response.json().username);
					localStorage.setItem('fileCode', this.fileCode);
					localStorage.setItem('img', this.img);
					localStorage.setItem('login_user', this.login_user);
					localStorage.setItem('rowCount', this.rowcount);
					localStorage.setItem('role', response.json() && response.json().r);

					localStorage.setItem('access_admin1', response.json() && response.json().access_admin1);
					localStorage.setItem('access_admin2', response.json() && response.json().access_admin2);
					localStorage.setItem('access_acc', response.json() && response.json().access_acc);
					localStorage.setItem('access_sales', response.json() && response.json().access_sales);
					localStorage.setItem('access_client', response.json() && response.json().access_client);
					localStorage.setItem('access_dash', response.json() && response.json().access_dash);
					localStorage.setItem('cc_id', response.json() && response.json().cc_id);
					localStorage.setItem('mailtype', response.json() && response.json().mailtype);
					this.getSrchUsers();
					this.r[0] = true;
					this.r[1] = response.json() && response.json().r;
				} else {
					this.r[1] = response.json() && response.json().status;
				}
				return this.r;
			}));
	}

	getSrchUsers() {
		this._http
			.post(User.API_ENDPOINT + 'get-search-users', '')
			.subscribe((response: Response) => {
				const res = { 'results': JSON.stringify(response) };
				const json = JSON.parse(res.results);
				const status = json.status;
				if (status === 200) {
					for (var i = 0; i < json.data['clients'].length; i++) {
						this.userarr.push({
							'id': json.data['clients'][i].user_id,
							'name': json.data['clients'][i].name,
							'mobile': (json.data['clients'][i].mobile == '') ? '' : json.data['clients'][i].mobile,
							'email': json.data['clients'][i].username,
							'type': 1,
						})
					}
					for (var i = 0; i < json.data['pipeline'].length; i++) {
						this.userarr.push({
							'id': json.data['pipeline'][i].pipe_id,
							'name': json.data['pipeline'][i].name,
							'mobile': (json.data['pipeline'][i].contct == '') ? '' : json.data['pipeline'][i].contct,
							'email': (json.data['pipeline'][i].email == '') ? '' : json.data['pipeline'][i].email,
							'type': 2,
						})
					}
					for (var k = 0; k < json.data['timesheet'].length; k++) {
						this.timesheet.push({
							'time_id': json.data['timesheet'][k].time_id,
							'timetype': json.data['timesheet'][k].timetype,
							'description': json.data['timesheet'][k].description,
							'sdate': json.data['timesheet'][k].sdate,
							'stime': json.data['timesheet'][k].stime
						});
					}
					localStorage.setItem('srchArr', JSON.stringify(this.userarr));
					localStorage.setItem('timeArr', JSON.stringify(this.timesheet));
				} else {
					this.logout();
				}
			});
	}

	toggle() {
		$('body').toggleClass('sidebar-mobile-main').removeClass('sidebar-mobile-secondary sidebar-mobile-right');
		if ($('.sidebar-main').hasClass('sidebar-fullscreen')) {
			$('.sidebar-main').removeClass('sidebar-fullscreen');
		}
	}

	toggle_sidebar() {
		$('body').toggleClass('sidebar-xs').removeClass('sidebar-mobile-main');
		this.revertBottomMenus();
	}

	revertBottomMenus() {
		$('.sidebar-main').find('.nav-sidebar').children('.nav-item-submenu').hover(function () {
			let totalHeight = 0,
				$this = $(this),
				navSubmenuClass = 'nav-group-sub',
				navSubmenuReversedClass = 'nav-item-submenu-reversed';

			totalHeight += $this.find('.' + navSubmenuClass).filter(':visible').outerHeight();
			if ($this.children('.' + navSubmenuClass).length) {
				if (($this.children('.' + navSubmenuClass).offset().top + $this.find('.' + navSubmenuClass).filter(':visible').outerHeight()) > document.body.clientHeight) {
					$this.addClass(navSubmenuReversedClass);
				} else {
					$this.removeClass(navSubmenuReversedClass);
				}
			}
		});
	}

	logout(): void {
		// clear token remove user from local storage to log user out
		this.token = null;
		localStorage.removeItem('currentUser');
		localStorage.removeItem('userName');
		localStorage.removeItem('fileCode');
		localStorage.removeItem('img');
		localStorage.removeItem('login_user');
		localStorage.removeItem('role');
		localStorage.removeItem('srchArr');
		localStorage.removeItem('mailtype');
		localStorage.removeItem('login_username');
		localStorage.removeItem('cc_id');
		localStorage.removeItem('timeArr');
		localStorage.removeItem('hint');
		localStorage.removeItem('rowCount');

		localStorage.removeItem('access_admin1');
		localStorage.removeItem('access_admin2');
		localStorage.removeItem('access_acc');
		localStorage.removeItem('access_sales');
		localStorage.removeItem('access_client');
		localStorage.removeItem('access_dash');
		this.router.navigate(['/']);
	}

	screenLock(): void {
		// clear token remove user from local storage to log user out
		this.token = null;
		localStorage.removeItem('currentUser');
		this.router.navigate(['/user-lock']);
	}
}