import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Response } from '@angular/http';
import { GeneralService } from '../shared/services/general.service';
import { HttpClient } from '@angular/common/http';
import { User } from '../shared/models/User';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
	loginForm: FormGroup;
	submitted = false;
	error = '';
	loading = false;
	btnText = 'SIGN IN';
	model: any = {};
	erroremail = false;
	notexist = false;
	mailerror = false;
	mailsend = false;
	load = false;

	constructor(
		public generalService: GeneralService,
		private formBuilder: FormBuilder,
		private router: Router,
		private _http: HttpClient,
	) { }

	ngOnInit() {
		this.generalService.logout();
		this.loginForm = this.formBuilder.group({
			uname: ['', [Validators.required, Validators.pattern(/[aA-zZ0-9'-]$/)]],
			password: ['', [Validators.required, Validators.pattern(/[aA-zZ0-9'-]$/)]],
		});
	}
	get f() { return this.loginForm.controls; }

	onSubmit() {
		this.submitted = true;
		if (this.loginForm.invalid) {
			return;
		}
		this.btnText = 'PLEASE WAIT...';
		this.loading = true;
		this.generalService.login(JSON.stringify(this.loginForm.value))
			.subscribe(result => {
				//console.log(result);
				if (result[0] === true) {
					this.error = '';
					if (result[1] === 0) {
						this.router.navigate(['sadmin']);
					} else if (result[1] === 1 || result[1] === 2) {
						this.router.navigate(['dashboard']);
					} else if (result[1] === 3) {
						this.router.navigate(['applicant']);
					} else if (result[1] === 4) {
						this.router.navigate(['coaching']);
					} else if (result[1] === 5) {
						this.router.navigate(['support']);
					} else if (result[1] === 6) {
						this.router.navigate(['salesperson']);
					} else {
						this.loading = false;
						this.btnText = 'SIGN IN';
						this.error = '* Something Went Wrong, Invalid Role!';
					}
				} else {
					this.btnText = 'SIGN IN';
					this.loading = false;
					if (result[1] === 400) {
						this.error = '* The Password Provided is Invalid';
					} else if (result[1] === 401) {
						this.error = '* Your Account is Pending Activation';
					} else if (result[1] === 402) {
						this.error = '* Please Provide a Valid Username';
					} else if (result[1] === 403) {
						this.error = '* Login is suspended for this user. Please contact Administrator for details';
					} else {
						this.error = '* Invalid Login. Please contact your Administrator';
					}
				}
			});
	}

	sendMail() {
		let email = this.model.email;
		if (email == null) {
			this.erroremail = true;
		} else {
			this.mailsend = false
			this.notexist = false;
			this.erroremail = false;
			this.load = true;
			this._http.post(User.API_ENDPOINT + 'password-recover', JSON.stringify({ email: this.model.email }))
				.subscribe((response: Response) => {
					this.load = false;
					let res = { 'results': JSON.stringify(response) };
					let myjson = JSON.parse(res.results);
					let status = myjson.status;
					if (status == 200) {
						this.mailsend = true;
					} else if (status == 400) {
						this.mailerror = true;
					} else if (status == 401) {
						this.notexist = true;
					} else {
						this.generalService.logout();
					}
				});
		}
	}

	rewrite(event) {
		this.error = '';
	}

}
