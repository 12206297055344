import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/shared/services/general.service';

@Component({
	selector: 'app-applicant-sidebar',
	templateUrl: './applicant-sidebar.component.html',
	styleUrls: ['./applicant-sidebar.component.css']
})
export class ApplicantSidebarComponent implements OnInit {

	constructor(public generalService: GeneralService) { }

	ngOnInit() {
	}

}
