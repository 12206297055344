import { Component, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/shared/services/general.service';

@Component({
	selector: 'app-dashboard-sidebar',
	templateUrl: './dashboard-sidebar.component.html',
	styleUrls: ['./dashboard-sidebar.component.css']
})

export class DashboardSidebarComponent implements OnInit {
	constructor(
		public generalService: GeneralService
	) { }

	access_dash = +localStorage.getItem('access_dash');

	ngOnInit() {
	}

	calc(value) {
		return (((this.access_dash & value) == 0) ? false : true);
	}

}