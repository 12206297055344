import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../services/general.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'app-user-menu',
	templateUrl: './user-menu.component.html',
	styleUrls: ['./user-menu.component.css']
})
export class UserMenuComponent implements OnInit {
	name = '';
	fileCode = '';
	img = '';
	role = '';
	constructor(
		public generalService: GeneralService,
		public sanitizer: DomSanitizer
	) { }

	ngOnInit() {
		this.name = localStorage.getItem('userName');
		this.fileCode = localStorage.getItem('fileCode');
		this.img = localStorage.getItem('img');
		this.role = localStorage.getItem('role');
	}

}
