import { Component, OnInit } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GeneralService } from '../shared/services/general.service';
import { observable } from 'rxjs';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.css']
})
export class PasswordRecoveryComponent implements OnInit {

  loginForm: FormGroup;
  submitted = false;
  error = '';
  loading = false;
  btnText = 'UPDATE';
  param1: any;
  constructor(public generalService: GeneralService, private formBuilder: FormBuilder, private router: Router,private route: ActivatedRoute) { }


  ngOnInit() {
    this.route
    .queryParams
    .subscribe(params => {
        // Defaults to 0 if no query param provided.
        this.param1 = params['token'];
      });
  }

}
