import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of, timer } from 'rxjs';
import { concatMap, map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class CustomPreloadingService implements PreloadingStrategy {
	constructor() { }

	/*preload(route: Route, fn: () => Observable<any>): Observable<any> {
		const requiredRole = route.data && route.data['requiredRole']

		if (requiredRole) {
			// Note, while using NgRx this can be replaces with a selector
			return this.roles().pipe(
				concatMap((roles) => {
					if (roles.some((r) => r.name === requiredRole)) {
						// The user has the required role, load the module
						return fn()
					}
					// The user doesn't have the required role, ignore the module
					return of(null)
				}),
			)
		}

		// The route doesn't have a required role, load the module
		return fn()
	}*/

	preload(route: Route, fn: () => Observable<any>): Observable<any> {
		const loadRoute = (delay) => delay > 0 ? timer(delay * 1000).pipe(map(() => fn())) : fn();
		if (route.data && route.data.preload) {
			const delay = route.data.loadAfterSeconds ? route.data.loadAfterSeconds : 0;
			return loadRoute(delay);
		}
		return of(null);
	}

}
