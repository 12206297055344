import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { map } from 'rxjs/operators';
import { HttpModule } from '@angular/http';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SubHeaderComponent } from './sub-header/sub-header.component';
import { ApplSubHeaderComponent } from '../applicant/appl-sub-header/appl-sub-header.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { DashboardSidebarComponent } from '../admin/dashboard-sidebar/dashboard-sidebar.component';
import { ApplicantSidebarComponent } from '../applicant/applicant-sidebar/applicant-sidebar.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		ReactiveFormsModule
	],
	declarations: [
		HeaderComponent,
		FooterComponent,
		SubHeaderComponent,
		ApplSubHeaderComponent,
		UserMenuComponent,
		DashboardSidebarComponent,
		ApplicantSidebarComponent,
		TermsAndConditionsComponent,
		PrivacyPolicyComponent,
		UserProfileComponent
	],
	exports: [
		HeaderComponent,
		FooterComponent,
		SubHeaderComponent,
		ApplSubHeaderComponent,
		UserMenuComponent,
		FormsModule,
		ReactiveFormsModule,
		HttpModule,
		CommonModule,
		DashboardSidebarComponent,
		ApplicantSidebarComponent
	],
})
export class SharedModule { }
