import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../shared/models/User';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { GeneralService } from '../shared/services/general.service';

@Component({
	selector: 'app-create-password',
	templateUrl: './create-password.component.html',
	styleUrls: ['./create-password.component.css']
})
export class CreatePasswordComponent implements OnInit {
	prop: any;
	model: any = {};
	errorconfpass = false;
	errornewpass = false;
	mismatcherr = false;
	loading = false;
	flag: any;
	constructor(
		private formBuilder: FormBuilder,
		private route: ActivatedRoute,
		private router: Router,
		private _http: HttpClient,
		private toastr: ToastrService,
		public generalService: GeneralService,
	) { }

	ngOnInit() {
		this.route
			.queryParams
			.subscribe(params => {
				// Defaults to 0 if no query param provided.
				this.prop = params['token'];
				this.flag = params['flag'];
			});
	}

	savePassword() {
		let token = this.prop;
		let password = this.model.newpass;
		let cpassword = this.model.confpass;
		if (password == null) {
			this.errornewpass = true;
		} else if (cpassword == null) {
			this.errornewpass = false;
			this.errorconfpass = true;
		} else if (password != cpassword) {
			this.errorconfpass = false;
			this.mismatcherr = true;
		} else {
			this.mismatcherr = false;
			this.loading = true;
			this._http.post(User.API_ENDPOINT + 'create-user-password', JSON.stringify({ newpassword: this.model.newpass, token: token, flag: this.flag }))
				.subscribe((response: Response) => {
					this.loading = false;
					let res = { 'results': JSON.stringify(response) };
					let myjson = JSON.parse(res.results);
					let status = myjson.status;
					if (status == 200) {
						this.toastr.success('Password Updated Successfully. Please login to access your account', 'Password Updated');
						this.router.navigate(['']);
					} else if (status == 400) {
						this.toastr.error('Oh snap! try submitting again', 'Password Failed to Update');
					} else {
						this.generalService.logout();
					}
				});
		}
	}

}
